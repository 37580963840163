import { useStoredQueryMiddleware } from "./middleware/store-query-middleware"
import * as VueRouter from "vue-router"
import { useLineMiddleware } from "./middleware/line-middleware"

const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  scrollBehavior() {
    return { top: 0 }
  },
  routes: [
    {
      path: "/select-type-register",
      component: () => import("@/views/SelectTypeRegister.vue"),
    },
    { path: "/register", component: () => import("@/views/Register.vue") },
    {
      path: "/",
      meta: {
        requiresLineUser: true,
      },
      children: [
        { path: "/reward-detail/:id", component: () => import("@/views/RewardDetail.vue") },
        { path: "/update-profile", component: () => import("@/views/UpdateProfile.vue") },
        { path: "/kuma-update-profile", component: () => import("@/views/KumaUpdateProfile.vue") },
        { path: "/terms-service", component: () => import("@/views/TermsService.vue") },
        { path: "/kuma-terms", component: () => import("@/views/KumaTerms.vue") },
        { path: "/address", component: () => import("@/views/Address.vue") },
        { path: "/customer-info", component: () => import("@/views/trane/CustomerInfo.vue") },
        { path: "/salz-travel-terms", component: () => import("@/views/salz/SalzTravelTerm.vue") },
        { path: "/salz-40-terms", component: () => import("@/views/salz/Salz40Term.vue") },
        { path: "/petronas-terms", component: () => import("@/views/policy/PetronasTerm.vue") },
        {
          path: "/ostech-terms",
          component: () => import("@/views/policy/OstechTerm.vue"),
        },
        {
          path: "/ostech-super-food-terms",
          component: () => import("@/views/policy/OstechSuperFoodTerm.vue"),
        },
        {
          path: "/ostech-cat-lover-terms",
          component: () => import("@/views/policy/OstechCatLoverTerm.vue"),
        },
        {
          path: "/ostech-ultra-pouch-terms",
          component: () => import("@/views/policy/OstechUltraPouchTerm.vue"),
        },
        {
          path: "/ostech-creamy-terms",
          component: () => import("@/views/policy/OstechCreamyTerm.vue"),
        },
        {
          path: "/",
          component: () => import("@/layouts/MainLayout.vue"),
          children: [
            { path: "/", component: () => import("@/views/Home.vue") },
            { path: "/home", component: () => import("@/views/Home.vue"), name: "home" },
            { path: "/reward", component: () => import("@/views/Reward.vue") },
            { path: "/profile", component: () => import("@/views/Profile.vue") },
            { path: "/announcement", component: () => import("@/views/Announcement.vue") },
          ],
        },
      ],
    },
    {
      path: "/:pathMatch(.*)*",
      component: () => import("@/views/NotFound.vue"),
    },
  ],
})

useStoredQueryMiddleware(router)
useLineMiddleware(router)

export default router
